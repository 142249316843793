<template>
  <div>
    <div class="header"><span class="ret" @click="ret">付款管理/</span> <span>付款详情</span></div>
    <div class="header-one">
      <div class="rask-title" v-if="taskDetail">
        <div class="rt-left">
          <div class="rt-left-title">{{taskDetail.title}}</div>
          <div class="rt-left-time">{{taskDetail.taskCreateTime}}发布</div>
        </div>
        <div class="rt-right" v-if="roleInfo==='102001'">
          <a-button style="margin-left:12px" type="primary" :loading="loading" v-if="taskDetail.payStatus==='10'" @click="Sh">审核</a-button>
          <a-button style="margin-left:12px" type="primary" :loading="loading" v-if="taskDetail.payStatus==='20'" @click="Fk">付款</a-button>
        </div>
      </div>
      <div class="rask-content" v-if="taskDetail">
        <a-row :gutter="24">
          <a-col :span="8" class="rc-mgb">付款单号：{{taskDetail.id}}</a-col>
          <a-col :span="8" class="rc-mgb" v-if="taskDetail.payType==='10'">付款类型：预付款</a-col>
          <a-col :span="8" class="rc-mgb" v-else>付款类型：尾款</a-col>
          <a-col :span="8" class="rc-mgb">付款人数：{{taskDetail.payNum}}</a-col>
          <a-col :span="8" class="rc-mgb" v-if="taskDetail.payType==='10'">付款金额(预付款)：{{taskDetail.payAmount===null?'--':taskDetail.payAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb" v-else-if="taskDetail.payType==='20'">付款金额(尾款)：{{taskDetail.payAmount===null?'--':taskDetail.payAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb" v-else>付款金额(其他)：{{taskDetail.payAmount===null?'--':taskDetail.payAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb">费用金额：{{taskDetail.payFeeAmount===null?'--':taskDetail.payFeeAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb">总金额：{{taskDetail.payTotalAmount===null?'--':taskDetail.payTotalAmount.toFixed(2)}}</a-col>
          <a-col :span="8" class="rc-mgb">付款状态：<span v-if="taskDetail.payStatus==='10'">待审核</span><span v-if="taskDetail.payStatus==='20'">审核成功</span><span v-if="taskDetail.payStatus==='30'">支付成功</span></a-col>
        </a-row>
      </div>
    </div>
    <div class="search">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="姓名">
              <a-input v-model="params.payeeName" placeholder="请输入姓名" />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <div class="table-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px;" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="content">
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="setTable"
        :columns="columns"
        :data="datas"
        :rowKey="(record) => record.id"
      >
        <span slot="userName" slot-scope="text,record">
          <div>{{record.payeeName}}</div>
          <div>{{record.payeePhoneNo}}</div>
          <div>{{record.payeeIdcardNo}}</div>
        </span>
        <span slot="bankInfo" slot-scope="text,record">
          <div>{{record.payeeBankName}}</div>
          <div>{{record.payeeBankNo}}</div>
        </span>
      </s-table>
    </div>
    <!-- 付款信息 -->
    <a-modal
      title="付款信息"
      :visible="visibleFk"
      :footer="null"
      @cancel="handleCancelFk"
      width="600px"
    >
      <ul class="fkxx" v-if="accountInfo">
        <li>
          <span class="left">账户余额</span>
          <div class="right">
            <span>{{accountInfo.normalBalanceAmount}}元</span>
            <a-button type="link" @click="showHkxx">请转入</a-button>
          </div>
        </li>
        <li>
          <span class="left">付款金额</span>
          <div class="right">
            <div>
              <span style="color:#FA541C;font-size:24px">{{(taskDetail.payAmount+taskDetail.payFeeAmount).toFixed(2)}}</span>
              <span>元</span>
            </div>
          </div>
        </li>
        <li>
          <span class="left">交易密码</span>
          <div class="right">
            <a-input v-model="payPwd" type="password" style="width:220px"></a-input>
            <a-button type="link" @click="resetMm">重置密码</a-button>
          </div>
        </li>
        <li>
          <span class="left"></span>
          <div class="right">
            <a-button type="primary" @click="ljfk" :loading="btnLoading">立即付款</a-button>
          </div>
        </li>
      </ul>
    </a-modal>
    <!-- 付款信息 -->
    <a-modal
      title="汇款信息"
      :visible="visiblehk"
      @ok="handleOkhk"
      @cancel="handleCancelhk"
      width="700px"
    >
      <div>
        <div class="hkxx-header"><a-icon theme="filled" style="color:#1890ff;margin-right:4px" type="info-circle" />温馨提示：请线下汇款，汇款时间在工作日9：00-16：30之间，预计2小时内到账，请注意查收到账短 信或邮件。</div>
        <ul class="hkxx-content">
          <li>
            <span class="left">付款账户</span>
            <span class="right">{{bankInfo.payEnterpriseName}}</span>
          </li>
          <li>
            <span class="left">收款账户名</span>
            <span class="right">{{bankInfo.bankAccountName}}</span>
          </li>
          <li>
            <span class="left">开户银行</span>
            <span class="right">{{bankInfo.bankName}}</span>
          </li>
          <li>
            <span class="left">银行卡号</span>
            <span class="right">{{bankInfo.bankNo}}</span>
          </li>
        </ul>
      </div>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/'
import Vue from 'vue'
import {
  taskWorkerPayItem,
  getPayDetails,
  taskWorkerPay,
  getBalance,
  prePay,
  taskPay,
  getUserBindEmail,
  resetPwd,
  getEnterpriseTradeDetaileds,
  getRoleType,
  getBanknum
} from '@/api/api'
export default {
  components: {
    STable
  },
  data () {
    return {
      params:{
        payeeName: ''
      },
      columns: [
        {
          title: '姓名/手机号/身份证',
          dataIndex: 'userName',
          scopedSlots: { customRender: 'userName' },
          width: '200px'
        },
        {
          title: '所属银行/银行卡号',
          dataIndex: 'phoneNo',
          scopedSlots: { customRender: 'bankInfo' },
          width: '200px'
        },
        {
          title: '付款金额',
          dataIndex: 'incomeAmount',
          customRender: text => text === null?'--':text.toFixed(2),
          width: '100px'
        },
        {
          title: '费率',
          dataIndex: 'feePoint',
          customRender: text => text === null?'--':(text*100).toFixed(2)+'%',
          width: '100px'
        },
        {
          title: '费用金额',
          dataIndex: 'feeAmount',
          customRender: text => text === null?'--':text.toFixed(2),
          width: '100px'
        },
        {
          title: '总金额',
          dataIndex: 'payAmount',
          customRender: text => text === null?'--':text.toFixed(2),
          width: '100px'
        },
        {
          title: '风控状态',
          dataIndex: 'riskControlStatus',
          customRender: text => text==='103301' ? '还未开始处理' : text==='103310' ? '已被风控：申请风控检查失败' : text==='103311' ? '已被风控：董监高员工校验失败' : text==='103312' ? '已被风控：超单人月累计金额限制' : text==='103313' ? '已被风控：超单人连续12月累计金额限制' : '未被风控',
          width: '150px'
        },
        {
          title: '支付状态',
          dataIndex: 'payStatus',
          customRender: text => text==='101401' ? '未付款' : text==='101402' ? '付款中' : text==='101411' ? '成功' : text==='101421' ? '付款失败' : text==='101422' ? '董监高校验失败' : text==='101410' ? '银行处理中' : text==='101423' ? '付款失败:余额不足' : text==='101424' ? '提现失败已退回' : '--',
          width: '100px'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: '150px'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          width: '200px'
        }
      ],
      visibleFk: false,
      visiblehk: false,
      taskId: '',
      taskDetail: null,
      payPwd: '',
      btnLoading: false,
      accountInfo: null,
      loading: false,
      userInfo: null,
      userEmail: '',
      roleInfo: '',
      bankInfo: ''
    }
  },
  created () {
    this.userInfo = Vue.ls.get('Login_Userinfo')
    this.taskId = JSON.parse(window.sessionStorage.getItem('taskInfo'))
    this.getRole()
  },
  mounted () {
    this.getTaskDetail()
    this.getBanknum()
  },
  methods: {
    // 获取银行卡号
    getBanknum () {
      getBanknum().then(res => {
        if (res.success) {
          this.bankInfo = res.result
        }
      })
    },
    // 查询用户当前角色
    getRole () {
      getRoleType().then(res => {
        // console.log(res)
        if (res.success) {
          this.roleInfo = res.result
        }
      })
    },
    // 返回
    ret () {
      window.localStorage.setItem('submenu','settlementManage')
      this.$router.push('/settlementManage')
    },
    // 获取任务详情
    getTaskDetail () {
      getPayDetails({id:this.taskId.id}).then(res => {
        if (res.success) {
          this.taskDetail = res.result
        }
      })
    },
    // 获取预付款列表getYfkList
    datas (parameter) {
      return getEnterpriseTradeDetaileds(Object.assign({tradeId:this.taskId.orderMainId},parameter,this.params)).then(res => {
        // res.result.data = res.result.records
        return res.result
      })
    },
    // 查询
    handleToSearchEnterprise () {
      this.$refs.setTable.refresh(true)
    },
    // 重置
    resetSearchEnterprise () {
      this.params = {
        payeeName: ''
      }
      this.$refs.setTable.refresh(true)
    },
    // 审核
    Sh () {
      let that = this
      that.loading = true
      this.$confirm({
        title: '确定审核?',
        // content: h => <div style="color:red;">Some descriptions</div>,
        onOk() {
          taskWorkerPay({payId: that.taskId.id}).then(res => {
            that.loading = false
            // console.log(res)
            if (res.success) {
              that.$message.success(res.message)
              that.getTaskDetail()
            } else {
              that.$message.error(res.message)
            }
          })
        },
        onCancel() {
          that.loading = false
        }
      });
    },
    // 付款
    Fk () {
      getBalance('bank').then(res => {
        if (res.success) {
          this.accountInfo = res.result
        }
      })
      getUserBindEmail().then(res => {
        // console.log(res)
        if(res.success) {
          this.userEmail = res.message
        }
      })
      this.visibleFk = true
    },
    // 重置密码
    resetMm () {
      let that = this
      this.$confirm({
        title: '确认重置密码？',
        content: '密码将发送至邮箱:' + that.userEmail,
        okText: '确认',
        cancelText: '取消',
        onOk() {
          resetPwd().then(res => {
            // console.log(res)
            if (res.success) {
              that.$message.success(res.message)
            } else {
              that.$message.error(res.message)
            }
          })
        },
      });
    },
    // 付款信息
    handleCancelFk () {
      this.visibleFk = false
      this.btnLoading = false
      this.payPwd = ''
    },
    // 请转入
    showHkxx () {
      this.visiblehk = true
    },
    // 立即付款
    ljfk () {
      // this.visibleFk = false
      this.btnLoading = true
      if (this.payPwd === '') {
        this.$message.error('支付密码不能为空')
        this.btnLoading = false
        return
      }
      prePay({payId:this.taskId.id}).then(res => {
        if (res.success) {
          if (res.result.useAllBalance===1) {
            taskPay({payId:this.taskId.id,prePayId:res.result.prePayId,pwd:this.payPwd}).then(result => {
              this.btnLoading = false
              if (result.success) {
                this.getTaskDetail()
                this.handleCancelFk()
                this.$message.success(result.message)
              } else {
                this.$message.error(result.message)
              }
            })
          } else {
            this.btnLoading = false
            this.$message.error('余额不足，请充值！')
          }
        }
      })
      // if (this.accountInfo.normalBalanceAmount<(this.taskDetail.payAmount+this.taskDetail.payFeeAmount)) {
      //   this.$message.error('余额不足，请充值！')
      //   this.btnLoading = false
      // } else {
      //   if (this.payPwd === '') {
      //     this.$message.error('支付密码不能为空')
      //     this.btnLoading = false
      //     return
      //   }
      //   prePay({payId:this.taskId.id}).then(res => {
      //     if (res.success) {
      //       taskPay({payId:this.taskId.id,prePayId:res.result.prePayId,pwd:this.payPwd}).then(result => {
      //         this.btnLoading = false
      //         if (result.success) {
      //           this.getTaskDetail()
      //           this.handleCancelFk()
      //           this.$message.success(result.message)
      //         } else {
      //           this.$message.error(result.message)
      //         }
      //       })
      //     }
      //   })
      // }
    },
    // 汇款信息
    handleCancelhk () {
      this.visiblehk = false
    },
    handleOkhk () {
      this.visiblehk = false
    }
  }
}
</script>
<style scoped lang="less">
.header{
  color: #000000;
  font-size: 14px;
  margin-bottom: 20px;
  .ret{
    color: rgba(0,0,0,0.65);
    cursor: pointer;
  }
}
.header-one{
  padding: 27px 24px;
  border: 1px solid #F0F0F0;
}
.rask-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rt-left{
    display: flex;
    align-items: flex-end;
    .rt-left-title{
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
    }
    .rt-left-time{
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}
.rask-content{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .rc-mgb{
    margin-top: 24px;
    span{
      color:#1890FF;
    }
  }
}
.search{
  width: 100%;
  padding: 32px 0 0 0;
  // display: flex;
  align-items: center;
}
.fkxx{
  list-style: none;
  padding: 0;
  li{
    display: flex;
    align-items: center;
    margin: 30px;
    color: rgba(51, 51, 51, 0.85);
    font-size: 14px;
    .left{
      width: 60px;
      margin-right: 24px;
    }
    .right{
      display: flex;
      align-items: center;
    }
  }
}
.hkxx-header{
  color: #333333;
  font-size: 14px;
  margin-bottom: 24px;
  line-height: 24px;
}
.hkxx-content{
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  li{
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #000000;
    .left{
      width: 200px;
      height: 56px;
      padding: 19px 24px;
      border-top: 1px solid #F0F0F0;
      border-left: 1px solid #F0F0F0;
    }
    .right{
      width: 466px;
      height: 56px;
      padding: 19px 24px;
      border-top: 1px solid #F0F0F0;
      border-left: 1px solid #F0F0F0;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
.pop-content{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #E1E4E8;
  .img{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .person{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 10px;
    span{
      &:last-child{
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        margin-left: 10px;
      }
    }
  }
  .construction{
    width: 230px;
    text-align: justify;
    font-size: 14px;
  }
}
.tags{
  padding-top: 10px;
}
</style>